import React from 'react';
import { Switch, Route } from 'react-router-dom';
import App from './App';
import Home from './components/Home';
import Counter from './components/Counter';
import routes from './routes.json';
import NavigationBar from './components/NavigationBar';

export default function Routes() {
  return (
    <App>
      <Switch>
        <Route exact path={routes.HOME} component={() => NavigationBar({passThrough: true, type: 'white'})} />
        <Route exact path={routes.DEMO} component={NavigationBar} />
      </Switch>
      <Switch>
        <Route exact path={routes.HOME} component={Home} />
        <Route path={routes.DEMO} component={Counter} />
        <Route component={NotFoundPage} />
      </Switch>
      {/* TODO add to routes.json */}
      <Route path="/counter/child" component={CounterChild} />
    </App>
  );
}

const CounterChild = () => <div><span>Counter Child</span></div>;
const NotFoundPage = () => <span>NotFoundPage</span>;