import React from 'react';
// import { Link } from 'react-router-dom';
import styles from './NavigationBar.module.sass';
// import routes from '../routes.json';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

export interface NavigationBarOptions {
  type?: string;
  passThrough?: boolean;
}

export default function NavigationBar(options: NavigationBarOptions) {
  // const { t } = useTranslation();
  const classBind = classNames.bind(styles);

  return (
    <div className={classBind({'navigationBar': true, 'passthrough': options.passThrough})}>
      {/* <Link className={styles.homeLink} to={routes.HOME}>
        <div className={styles.logo}>Si</div>
      </Link>
      <nav className={classBind({'black': options.type !== 'white'})}>
        <Link to={routes.MODELS}><span>{t('section.models')}</span></Link>
        <Link to={routes.PERFORMANCE}><span>{t('section.performance')}</span></Link>
      </nav> */}
    </div>
  )
}