import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Counter.module.sass';
import routes from '../routes.json';
import { StoreDefinition, useStore } from '../state/Store';

export default function Counter(): JSX.Element {
  const { data, set } = useStore();

  const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div>
      <div className={styles.backButton} data-tid="backButton">
        <Link to={routes.HOME}>
          <i className="fa fa-arrow-left fa-3x" />
        </Link>
      </div>
      <div className={`counter ${styles.counter}`} data-tid="counter">
        {data.count}
      </div>
      <div className={styles.btnGroup}>
        <button
          className={styles.btn}
          onClick={() => set((state: StoreDefinition) => void ++state.data.count)}
          data-tclass="btn"
          type="button"
        >
          <i className="fa fa-plus" />
        </button>
        <button
          className={styles.btn}
          onClick={() => set((state: StoreDefinition) => void --state.data.count)}
          data-tclass="btn"
          type="button"
        >
          <i className="fa fa-minus" />
        </button>
        <button
          className={styles.btn}
          onClick={() => set((state: StoreDefinition) => {
            if (state.data.count % 2 !== 0) {
              ++state.data.count;
            }
          })}
          data-tclass="btn"
          type="button"
        >
          odd
        </button>
        <button
          className={styles.btn}
          onClick={async () => {
            await delay(2000);
            set((state: StoreDefinition) => void ++state.data.count);
          }}
          data-tclass="btn"
          type="button"
        >
          async
        </button>
      </div>
    </div>
  );
}
