import React from 'react';
import home from './Home.module.sass';
import routes from '../routes.json';
import { Link } from 'react-router-dom';
import { StoreDefinition, useStore } from '../state/Store';
import { useTranslation } from 'react-i18next';

export default function Home(): JSX.Element {

  const { t } = useTranslation();
  const { data, set } = useStore();

  const handleInput = (e: any): void => {
    const r = /([A-Za-z]*)([0-9]*)/gm.exec(e.target.value);
    r && set((state: StoreDefinition) => {
      state.data.text = r[1];
      if (r[2]) {
        state.data.count = parseInt(r[2]);
      }
    });
  }

  return (
    <>
      <div className={home.hero}>
        <div className={home.logo}>
          <span>{data.text || 'Ne'}</span>
          <div className={home.number}>{data.count || 10}</div>
        </div>
        <Link to={routes.DEMO}><span>{t('home.demo')}</span></Link>
        <input type="text" spellCheck={false} onChange={handleInput}></input>
      </div>
    </>
  );
}
