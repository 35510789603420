import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';

export interface StoreDefinition {
  data: {
    count: number,
    text: string
  },
  set: Function
}

export const useStore = create<StoreDefinition>(devtools((set) => ({
  data: {
    count: 0,
    text: ""
  },
  set: (fn: any) => set(produce(fn))
})));