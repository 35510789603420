import React from 'react';
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n/i18n';
import 'normalize.css';
import './styles/app.global.css';

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line global-require
  const Root = require('./components/Root').default;
  render(
    <>
      <Root />
    </>,
    document.getElementById('root')
  );

  // set to register() for PWA
  serviceWorkerRegistration.unregister();
  reportWebVitals();
});


