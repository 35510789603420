import { createBrowserHistory } from 'history';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import Routes from '../Routes';
import styles from './Root.module.sass';
import global from '../styles/Global.module.sass';
import { Router } from 'react-router-dom';

const history = createBrowserHistory();

class Root {
  static render = () => (
    <>
      <div className={global.all}>
        {/* inject global styles as high up as possible */}
      </div>
      <div className={styles.main}>
        <Router history={history}>
          <Routes />
        </Router>
      </div>
    </>
  );
}

export default hot(Root.render);
